import React, {useEffect, useMemo, useState} from 'react';
import {ILot} from "../../../../../utils/interfaces/tannery_raw_material/ILot";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiGetLots from "./api-hooks/useApiGetLots";
import {CustomAxiosError} from "../../../../../logics/api_config";
import useApiDeleteLot from "./api-hooks/useApiDeleteLot";
import {useTanneryRawMaterial} from "../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import customCellRenderer from "./customCellRenderer";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import LotsForm from "./LotsForm";
import {Avatar, Box} from "@mui/material";
import {FiDownloadCloud} from "react-icons/fi";
import Loading from "../../../../ui/Loading";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import {filterLotList} from "./filterUtils";
import ChoicesTable from "./choices/ChoicesTable";
import MuiCardContent from "../../../../ui/mui/mui-display-data/MuiCardContent";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {cutString, formatFloatToEuro, formatFloatToLocale, formatIntegerToLocale} from "../../../../../logics/helpers";
import MRTLotsFiltersPopOver from "./MRTLotsFiltersPopOver";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import {HiOutlineViewList} from "react-icons/hi";


interface Props {
    isFromPeopleArticle?: boolean;
}

interface ILotWithCustomField extends ILot {
    custom_field: JSX.Element;
}

const LotsTable = ({isFromPeopleArticle = false}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [openFilters, setOpenFilter] = useState<boolean>(true)

    const {selectedLot, selectLot} = useTanneryRawMaterial()

    const {data, isLoading, error, isFetching} = useApiGetLots(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    const handleDownloadPdf = (row: ILot) => {
        if (row.pdf_doc) {
            const anchor = document.createElement('a');
            anchor.href = row.pdf_doc;
            anchor.target = '_blank';
            anchor.download = 'document.pdf';
            anchor.click();
            anchor.remove();
        }
    };

    const [rows, setRows] = useState<ILot[] | undefined>()

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteLot(selectedCompany?.company_token, selectedLot?.id);
    const {mutateAsync: apiDeleteOrder} = deleteMutation;
    const removeDeletedRow = (deletedRowId: number | undefined) => {
        if (selectedLot) {
            const updatedRows = data?.filter(
                (row) => row.id !== deletedRowId
            );
            setRows(updatedRows);
        }
    };


    const handleConfirmDelete = async () => {
        try {

            await apiDeleteOrder({companyToken: selectedCompany?.company_token, lot_id: selectedLot?.id});
            await queryClient.invalidateQueries({queryKey: ['getLots']});
            displayMessage('Lot deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the Lot');
            }
        }
        removeDeletedRow(selectedLot?.id);
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const [lotsWithCustomField, setLotsScheduleWithCustomField] = useState<ILotWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: ILotWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme),
        }));
        setLotsScheduleWithCustomField(dataWithCustomField);
    }, [rows]);

    const [filterLwgSiteType, setFilterLwgSiteType] = useState<string>('');
    const [filterChoiceName, setFilterChoiceName] = useState<string>('');
    const handleToggleFilters = () => {
        setFilterLwgSiteType('');
        setFilterChoiceName('');
        setOpenFilter(!openFilters);
    };


    useEffect(() => {
        if (data) {
            const filteredData = filterLotList(data, filterLwgSiteType, filterChoiceName);
            setRows(filteredData);
        }
    }, [data, filterChoiceName, filterLwgSiteType]);


    const columns = useMemo<MRT_ColumnDef<ILot>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'lot_id',
                header: t('lot_id'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'people.people_name',
                header: t('people_name'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'lwg_site.lwg_site_name',
                header: t('lwg_site_name'),
                filterFn: 'contains',
                accessorFn: (row) => (row.lwg_site.lwg_site_name && cutString(row.lwg_site.lwg_site_name, 25)),
            },
            {
                accessorKey: 'lot_note',
                header: t('note'),
                filterFn: 'contains',
                accessorFn: (row) => (row.lot_note && cutString(row.lot_note, 25)),
            },
            {
                accessorKey: 'pcs',
                header: t('pcs'),
                filterFn: 'between',
                accessorFn: (row) => (formatIntegerToLocale(row.pcs))
            },
            {
                accessorKey: 'kgs',
                header: t('kgs'),
                filterFn: 'between',
                enableGlobalFilter: false,
                accessorFn: (row) => (formatIntegerToLocale(row.kgs))
            },
            {
                accessorKey: 'sqm',
                header: t('sqm'),
                filterFn: 'between',
                enableGlobalFilter: false,
                accessorFn: (row) => (formatFloatToLocale(row.sqm))
            },
            {
                accessorKey: 'total_value_eur',
                header: t('tot eur'),
                filterFn: 'between',
                enableGlobalFilter: false,
                accessorFn: (row) => (formatFloatToEuro(row.total_value_eur))
            },
            {
                accessorFn: (row) => ((row.total_value_eur && row.sqm) ? formatFloatToEuro(row.total_value_eur / row.sqm) + '/sqm' : ''),
                header: t('eur/sqm'),
                accessorKey: 'eur_sqm',
                filterFn: 'between',
                enableGlobalFilter: false,
            },
            {
                accessorFn: (row) => ((row.total_value_eur && row.pcs) ? formatFloatToEuro(row.total_value_eur / row.pcs) + '/pc' : ''),
                header: t('eur/pcs'),
                accessorKey: 'eur_pcs',
                filterFn: 'between',
                enableGlobalFilter: false,
            },
            {
                accessorFn: (row) => {
                    return row.kgs ? formatFloatToEuro(row.total_value_eur / row.kgs) + '/kg' : '';
                },
                header: t('eur/kgs'),
                accessorKey: 'eur_kgs',
                filterFn: 'between',
                enableGlobalFilter: false,
            },

        ],
        []
    );


    const table = useMaterialReactTable<ILot>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: lotsWithCustomField ? lotsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 100, pageIndex: 0},
            columnVisibility: {
                total_value_eur: !isFromPeopleArticle,
                eur_sqm: !isFromPeopleArticle,
                eur_pcs: !isFromPeopleArticle,
                eur_kgs: !isFromPeopleArticle,
            }
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        //note_marco: MRT con MarginX e riduzioni carattere personalizzate
        muiTableBodyRowProps: ({row}) => ({
            ...getMuiTableBodyRowProps(theme),
            sx: {
                '& .MuiTableCell-root': {
                    padding: '0px 4px',
                    // fontSize: '12px'
                },
            },
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={() => {
                    selectLot(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    selectLot(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<FiDownloadCloud/>}
                key="download"
                label={t("download_pdf_doc")}
                onClick={() => {
                    handleDownloadPdf(row.original);
                    closeMenu();
                }}
                table={table}
                disabled={!row.original.pdf_doc}
            />,

        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <HiOutlineViewList color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'lots'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            selectLot(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_lot'}/>

                    <MRTLotsFiltersPopOver
                        onToggle={handleToggleFilters}
                        filterLwgSiteType={filterLwgSiteType}
                        setFilterLwgSiteType={setFilterLwgSiteType}
                        filterChoiceName={filterChoiceName}
                        setFilterChoiceName={setFilterChoiceName}
                    />
                </>
            </Box>
        ),


        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('choices'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            (
                <MuiBorderedBox
                >
                    <MuiCardContent value={row.original.lot_note}
                                    label={'lot_note'}/>
                    <ChoicesTable lot={row.original}/>
                </MuiBorderedBox>
            )
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                <MaterialReactTable table={table}/>
                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'90vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                >
                    <LotsForm
                        lotToEdit={selectedLot ? selectedLot : null}
                        onClose={() => setIsEditFormOpen(false)}/>
                </MuiModal>

                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }

            </>}
    </>;
};

export default LotsTable;
