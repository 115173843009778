import React, {useState} from 'react';
import {Box, Popover} from "@mui/material";
import {IconContext} from "react-icons";
import {useTranslation} from "react-i18next";
import {IWasteMovement} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";
import WasteMovementReferencesDocCard from "./WasteMovementReferencesDocCard";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {GoCrossReference} from "react-icons/go";
import {
    PiDownloadSimpleFill,
    PiPencilSimpleDuotone,
    PiPencilSimpleSlashDuotone,
    PiUploadSimpleFill
} from "react-icons/pi";
import WasteMovementCard from "./WasteMovementCard";


const useCustomCellRenderer = (row: IWasteMovement, theme: any) => {
    const {t} = useTranslation()
    const [isCardOpem, setIsCardOpen] = useState(false)
    const [isVasteMovementCardOpen, setIsVasteMovementCardOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


    const getIconsForCustomField = (row: IWasteMovement, theme: any) => {
        const icons = [];

        icons.push(null)

        switch (row.movement_type) {
            case 'annulment':
                icons.push(
                    <MRTMuiButton
                        small={true}
                        icon={<PiPencilSimpleSlashDuotone color={theme.palette.primary.main}/>}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setAnchorEl(event.currentTarget);
                            setIsVasteMovementCardOpen(true)
                        }}
                        label={'info'}/>
                )
                break;
            case 'rectification':
                icons.push(
                    <MRTMuiButton
                        small={true}
                        icon={<PiPencilSimpleDuotone color={theme.palette.primary.main}/>}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setAnchorEl(event.currentTarget);
                            setIsVasteMovementCardOpen(true)
                        }}
                        label={'info'}/>
                )
                break;
            case 'load':
                icons.push(
                    <PiDownloadSimpleFill color={theme.palette.textAppGarageColor.tw700}/>
                );
                break;
            case 'download':
                icons.push(
                    <PiUploadSimpleFill color={theme.palette.textAppGarageColor.tw700}/>
                );
                break;
            default:
                icons.push(
                    <PiUploadSimpleFill color={theme.palette.textAppGarageColor.tw700}/>
                );
                break;
        }

        if (row.movement_references && row.movement_references.length > 0) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    icon={<GoCrossReference
                        color={theme.palette.primary.main}/>}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                        setIsCardOpen(true)
                    }}
                    label={'movement references'}/>
            )
        }

        if (row.has_been_annulled_by) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    icon={<PiPencilSimpleSlashDuotone color={theme.palette.error.main}/>}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                        setIsVasteMovementCardOpen(true)
                    }}
                    label={'info'}/>
            )
        }

        if (row.has_been_rectified_by) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    icon={<PiPencilSimpleDuotone color={theme.palette.error.main}/>}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                        setIsVasteMovementCardOpen(true)
                    }}
                    label={'info'}/>
            )
        }


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
                <Popover
                    id={'edit_popover'}
                    open={isCardOpem}
                    onClose={() => setIsCardOpen(false)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    // transformOrigin={{
                    //     vertical: 'top',
                    //     horizontal: 'right',
                    // }}
                >
                    <WasteMovementReferencesDocCard
                        waste_movement={row}
                    />

                </Popover>
                <Popover
                    id={'edit_popover'}
                    open={isVasteMovementCardOpen}
                    onClose={() => setIsVasteMovementCardOpen(false)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <WasteMovementCard obj={row}/>
                </Popover>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;